import React from "react"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { shuffleArray, getUnrelatedReviews, getHearFromHeadingAndBlurbObject } from "../utils/utils"
import SEO from "../components/seo"

import Button from '@bit/azheng.joshua-tree.button';
import OffsetHeadingCTA from "../components/CTA/OffsetHeadingCTA"
import FixedFacewall from "../components/carousel/FixedFacewall"
import ReviewRowsSection from "../components/ReviewRowsSection"
import ImageMeta from "../components/ImageMeta"

import { Link, graphql } from "gatsby"

var showdown = require("showdown")

const PageWithoutVideoPage = ({ data, pageContext }) => {

const post = data.allUniquePagesJson.nodes[0];
  var language;
  var converter;
  var text;
  var html;
  var cta;
  var ctaHtml;

  function markdownToHtml() {
    converter = new showdown.Converter()
    text = post.body
    cta = post.ctaSection
    html = converter.makeHtml(text.replace(/[™®©]/g, '<sup>$&</sup>'))

    ctaHtml = converter.makeHtml(cta)
  }

  markdownToHtml()

  function createBodyHtml() {
    return { __html: html }
  }
  function createCtaHtml() {
    return { __html: ctaHtml }
  }


  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO title={post.metaTitle} description={post.metaDescription} schemaPage={post.schemaPage ? post.schemaPage : ""} />
        <div>
        <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={language === "es" ? "/es/procedimientos/" : "/procedures/"}
          />
          <section className="procedure top-section section white-back joshua-tree-content">
            <div className="columns">
              <div className="column is-5"></div>
              <div
                className="column"
                dangerouslySetInnerHTML={createBodyHtml()}
              ></div>
              <div className="column is-5"></div>
            </div>
          </section>

          <div>
          <OffsetHeadingCTA
              headingSideColumnIs={5}
              sideColumnIs={4}
              headingJSX={
                <h3 style={{ marginBottom: "1.5rem" }}>
                  {language === "es"
                    ? "Preguntas? Estamos Aquí Para Ayudar"
                    : "Questions? We Are Here to Help"}
                </h3>
              }
              paragraphJSX={
                language === "es" ? (
                  <p className="large">
                    Nuestro personal está aquí para responder sus preguntas y
                    guiarlo a lo largo de su viaje con nuestra práctica. No dude
                    en contactarnos si tiene alguna pregunta o inquietud con
                    respecto a su tratamiento.
                  </p>
                ) : (
                  <p className="large">
                    Our staff is here to answer your questions and guide you
                    throughout your journey with our practice. Feel free to{" "}
                    <Link to="/contact-us-in-mount-pleasant-sc/">contact us</Link> if
                    you have any questions or concerns regarding your treatment.
                  </p>
                )
              }
              buttonText={language === "es"
              ? "Contáctenos"
              : "Contact Us"}
              buttonUrl={language === "es"
              ? "/es/contactenos-oklahoma-city-ok/"
              : "/contact-us-in-mount-pleasant-sc/"}
            />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query singlePageNoVideos ($loneUrl: String!)  {
    allUniquePagesJson(filter: {title: {eq: $loneUrl}}) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        body
      }
    }
}
`

export default PageWithoutVideoPage
